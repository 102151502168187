import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ERROR_PATHS } from '@app/error/error-paths';
import {
  PatchInternetRegistrationPartialSuccessResponse,
  PatchInternetRegistrationResponse
} from '@nationwide/api-client-internet-registration-v3';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import {
  DynamicChecklistItem,
  PASSWORD_DYNAMIC_CHECKLIST,
  USERNAME_DYNAMIC_CHECKLIST
} from '@shared/dynamic-checklist/dynamic-checklist-item.model';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { Customer, SessionKey } from '@shared/storage/storage.model';
import { validatePasswordsMatch } from '@shared/validation/validate-passwords-match';
import { validateUsernamePasswordDoNotMatch } from '@shared/validation/validate-username-password-not-match';
import { filter } from 'rxjs/operators';
import { FORGOT_CREDENTIALS_PATHS } from '../forgot-credentials-paths';
import { ForgotCredentialsBaseComponent } from '../shared/forgot-credentials-base.component';
import { ForgotCredentialsService } from '../shared/forgot-credentials.service';
import { CONTROLS, CONTROL_ERRORS } from './reset-username-password.model';

@UntilDestroy()
@Component({
  selector: 'ciam-reset-username-password',
  templateUrl: './reset-username-password.component.html',
  styleUrls: ['./reset-username-password.component.scss']
})
export class ResetUsernamePasswordComponent extends ForgotCredentialsBaseComponent implements OnInit {
  resetUsernamePasswordForm: UntypedFormGroup;
  resetUsernamePasswordControls: Controls = CONTROLS;
  resetUsernamePasswordControlErrors: ControlErrors = CONTROL_ERRORS;

  currentUsernameInput = '';
  usernameDynamicChecklist: Array<DynamicChecklistItem> = USERNAME_DYNAMIC_CHECKLIST;

  currentPasswordInput = '';
  passwordDynamicChecklist: Array<DynamicChecklistItem> = PASSWORD_DYNAMIC_CHECKLIST;

  readonly spinnerMessage = 'Updating your online account...';

  protected className = 'ResetUserNamePasswordComponent';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _navigationService: NavigationService,
    private _forgotCredentialsService: ForgotCredentialsService,
    private _customCookieService: CustomCookieService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
    this.hideSpinnerOnLoad = false;
  }

  ngOnInit() {
    this.spinnerService.show();
    this.logger.info(this.className, 'Resetting username/password.');

    this._contentHeaderService.reset(this.contentHeaderModel);
    this._forgotCredentialsService.reset();
    this._initializeForm();
    this._retrieveInitialUserName();

    this.logger.info(this.className, `Current username: ${this.initialUserName}`);
  }

  handleShowHideClick(event: Event) {
    this.logger.logElementClick(this.className, event);
  }

  _submit() {
    this.spinnerService.show(this.spinnerMessage);

    this.logger.info(this.className, 'Attempting to update credentials.');

    const newUsername = this.resetUsernamePasswordForm.get('username').value;

    this._manageUsername(newUsername, this.initialUserName);

    this._forgotCredentialsService
      .updateCredentials(this.resetUsernamePasswordForm.get('password').value, newUsername)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.status === 200) {
            this._handleSuccess(response, newUsername);
          } else if (response.status === 207) {
            const failedToProcess = (response.body as PatchInternetRegistrationPartialSuccessResponse).failedToProcess;
            const fupFailures = failedToProcess.filter(
              attribute => attribute === 'userName' || attribute === 'password'
            );

            this.logger.warn(this.className, `Partial success, response received. ${failedToProcess} not updated.`);

            if (response.body.message?.developerMessages?.[0]?.includes('The password provided is being reused')) {
              this.logger.warn(this.className, 'Password has already been used.');
              this.resetUsernamePasswordForm.get('password').setErrors({ previousPasswordUsed: true });
              this.spinnerService.hide();
            } else if (fupFailures.length > 0) {
              this._navigationService.navigateToError(
                this.className,
                new Error(`Failed to reset ${fupFailures}.`),
                ERROR_PATHS.systemError
              );
            } else {
              this._handleSuccess(response, newUsername);
            }
          }
        },
        error => {
          if (error instanceof HttpErrorResponse && error.error?.code === 4215) {
            this.logger.warn(this.className, 'Username unavailable.');
            this.resetUsernamePasswordForm.get('username').setErrors({ usernameUnavailable: true });
            this.spinnerService.hide();
          } else if (error instanceof HttpErrorResponse && (error.error?.code === 4221 || error.error?.code === 4222)) {
            this._navigationService.navigateToError(
              this.className,
              error,
              ERROR_PATHS.accessLocked,
              `Online account ${error.error.code === 4222 ? 'MFA ' : ''}locked during reset username/password.`
            );
          } else if (error instanceof HttpErrorResponse && error.error?.code === 5908) {
            this.logger.warn(this.className, 'Password has already been used.');
            this.resetUsernamePasswordForm.get('password').setErrors({ previousPasswordUsed: true });
            this._manageUsername(newUsername, '');
            this.spinnerService.hide();
          } else {
            this.logger.error(this.className, 'Error occurred updating credentials.');
            this._navigationService.navigateToError(this.className, error, ERROR_PATHS.systemError);
          }
        }
      );
  }

  _handleSuccess(
    response: HttpResponse<PatchInternetRegistrationResponse | PatchInternetRegistrationPartialSuccessResponse>,
    newUsername: string
  ) {
    const usernameMessage = `Username and password updated. Username is ${newUsername}.`;
    this.logger.info(this.className, `${newUsername ? usernameMessage : 'Password updated.'}`);

    if (response.body.device?.deviceToken) {
      this._customCookieService.deviceToken = response.body.device.deviceToken;
    }
    if (newUsername) {
      this.sessionService.setModifiedSessionObject(newUsername, SessionKey.CUSTOMER, 'userName');
    }

    this._navigationService.navigate(`${this.baseRoute}/${FORGOT_CREDENTIALS_PATHS.resetUsernamePasswordConfirmation}`);
  }

  _retrieveInitialUserName() {
    const customer: Customer = this.sessionService.get(SessionKey.CUSTOMER);
    this._forgotCredentialsService
      .getCurrentUsername(customer.guid)
      .pipe(untilDestroyed(this))
      .subscribe(
        userName => {
          this.sessionService.setModifiedSessionObject(userName, SessionKey.CUSTOMER, 'userName');
          this.spinnerService.hide();
        },
        error => {
          this.logger.error(this.className, 'Initial userName could not be retrieved.');
          this._navigationService.navigateToError(this.className, error, ERROR_PATHS.systemError);
        }
      );
  }

  private _manageUsername(newUsername: string, formUsername: string) {
    // If no username is provided, set the form username to the current username so
    // that password managers recognize an existing account is being updated
    if (newUsername === '') {
      this.resetUsernamePasswordForm.controls['username'].setValue(formUsername, { emitEvent: false });
    }
  }

  private _initializeForm() {
    this.resetUsernamePasswordForm = this._formBuilder.group(
      {
        username: ['', [...this._buildPatternValidators(this.resetUsernamePasswordControls.username.patterns)]],
        password: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.resetUsernamePasswordControls.password.patterns)]
        ],
        passwordConfirmation: ['', [Validators.required]]
      },
      {
        updateOn: 'submit',
        validators: [validatePasswordsMatch, validateUsernamePasswordDoNotMatch]
      }
    );

    this.resetUsernamePasswordForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.resetUsernamePasswordForm.valid)
      )
      .subscribe(() => {
        this._submit();
      });
  }

  get contentHeaderModel(): ContentHeaderModel {
    return this.fromRegistration
      ? {}
      : {
          title: 'Forgot username/password',
          progressTitle: 'Reset password',
          progressPercent: 66
        };
  }

  get initialUserName(): string {
    return (this.sessionService.get(SessionKey.CUSTOMER) as Customer)?.userName;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.resetUsernamePasswordForm;
  }

  protected get controls(): Controls {
    return this.resetUsernamePasswordControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.resetUsernamePasswordControlErrors;
  }
}
