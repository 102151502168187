<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="version-content" class="container">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <th scope="row">Version</th>
              <td id="version">{{ version }}</td>
            </tr>
            <tr>
              <th scope="row">Version date</th>
              <td id="version-date">2024-12-03 03:13:45 EST</td>
            </tr>
            <tr>
              <th scope="row">Environment name</th>
              <td id="environment-name">{{ environmentName }}</td>
            </tr>
            <tr>
              <th scope="row">Build number</th>
              <td id="build-number">3</td>
            </tr>
            <tr>
              <th scope="row">Git branch</th>
              <td id="git-branch">project_titans_s3tocnp-with_revamped_widget</td>
            </tr>
            <tr>
              <th scope="row">Git commit hash</th>
              <td id="git-commit">fcf798de1c8be7cdd6b16e7e7875297ac001c7f9</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>
